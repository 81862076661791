module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Traqpad","short_name":"Traqpad","start_url":"/","background_color":"#FFFFFF","theme_color":"#0055aa","display":"standalone","icon":"src/images/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fa524dca553770af721886d16df811a8"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-M14XT4GH73"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0,"send_page_view":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
